<template>
  <v-row
    v-if="resevationDone"
    class="final"
    style="overflow: hidden; height: 100%"
  >
    <v-img height="100%" src="@/assets/background.jpg">
      <v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="12" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.customer_studio") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="$store.getters['auth/isLoggedIn']">
        <v-btn
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'appointmentList' })"
          >{{ $tc("appointments.go", 2) }}</v-btn
        >
      </v-row>
      <v-row justify="center" v-else>
        <v-btn style="height: 30px" outlined :href="ap_id">{{
          $t("appointments.go_customer")
        }}</v-btn>
      </v-row>
    </v-img>
  </v-row>
  <tattooer-card v-else>
    <template slot-scope="{}">
      <v-row v-if="resevationDone">
        <v-col cols="12">
          <h1>{{ $t("inscriptions.customer_tattooer") }}</h1>
        </v-col>
      </v-row>

      <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>
          <template>
            <v-stepper-step
              step="1"
              :complete="e1 > 1"
              v-bind:class="[{ pointer: e1 > 1 }]"
              @click="e1 > 1 ? (e1 = 1) : null"
              ><div
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.select_studio") }}
              </div></v-stepper-step
            >
            <v-divider style="margin-top: 4em"></v-divider>
          </template>
          <v-stepper-step
            :step="2"
            :complete="e1 > 2"
            v-bind:class="[{ pointer: e1 > 2 }]"
            @click="e1 > 2 ? (e1 = 2) : null"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              <span v-if="artist == 'piercer'">
                {{ $t("appointments.info_piercing") }}
              </span>
              <span v-else-if="artist == 'laser'">
                {{ $t("appointments.info_laser") }}
              </span>
              <span v-else-if="artist == 'microblading'">
                {{ $t("appointments.info_microblading") }}
              </span>
              <span v-else>{{ $t("appointments.info_tattoo") }}</span>
            </div>
          </v-stepper-step>

          <v-divider style="margin-top: 4em"></v-divider>
          <v-stepper-step
            :step="3"
            :complete="e1 > 3"
            v-bind:class="[{ pointer: e1 > 3 }]"
            @click="e1 > 3 ? (e1 = 3) : null"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{
                $store.getters["inscriptions/getTattooerLaborExange"](
                  customer.studio
                ) === "day"
                  ? $t("appointments.day_hour")
                  : $t("appointments.day")
              }}
            </div>
          </v-stepper-step>
          <v-divider style="margin-top: 4em"></v-divider>
          <v-stepper-step
            :step="4"
            :complete="e1 > 4"
            v-bind:class="[{ pointer: e1 > 4 }]"
            @click="e1 > 4 ? (e1 = 4) : null"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("appointments.info_cliente") }}
            </div>
          </v-stepper-step>

          <v-row
            v-if="$vuetify.breakpoint.smAndDown"
            style="display: block; width: 100%; margin-top: -30px"
            justify="center"
            ><v-col>
              <div
                v-if="e1 === 4"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.info_cliente") }}
              </div>
              <div
                v-else-if="e1 === 1"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{ $t("appointments.select_tattooer") }}
              </div>
              <div
                v-else-if="e1 === 2"
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                <span v-if="artist == 'piercer'">
                  {{ $t("appointments.info_piercing") }}
                </span>
                <span v-else-if="artist == 'laser'">
                  {{ $t("appointments.info_laser") }}
                </span>
                <span v-else-if="artist == 'microblading'">
                  {{ $t("appointments.info_microblading") }}
                </span>
                <span v-else>{{ $t("appointments.info_tattoo") }}</span>
              </div>
              <div
                v-else
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                "
              >
                {{
                  $store.getters["inscriptions/getTattooerLaborExange"](
                    customer.studio
                  ) === "day"
                    ? $t("appointments.day_hour")
                    : $t("appointments.day")
                }}
              </div>
            </v-col>
          </v-row>
        </v-stepper-header>
        <v-row justify="center">
          <v-col cols="11">
            <v-stepper-items>
              <v-stepper-content step="1" class="px-1 px-md-6">
                <studio-select
                  @validated="e1++"
                  @back="$router.back()"
                ></studio-select>
              </v-stepper-content>
              <v-stepper-content :step="2">
                <div v-if="artist == 'tattooer'">
                  <tattoo-info @validated="e1++" @back="e1--"></tattoo-info>
                </div>
                <div v-else-if="artist == 'piercing'">
                  <PiercingInfo @validated="e1++" @back="e1--"></PiercingInfo>
                </div>
                <div v-else-if="artist == 'laser'">
                  <laser-info @validated="e1++" @back="e1--" />
                </div>
                <div v-else>
                  <microblading-info @validated="e1++" @back="e1--" />
                </div>
              </v-stepper-content>
              <v-stepper-content :step="3">
                <PrepareTimeSelect @validated="e1++" @back="e1--" />
              </v-stepper-content>
              <v-stepper-content :step="4">
                <customer-info @back="e1--" @validated="save"></customer-info>
              </v-stepper-content>
            </v-stepper-items>
          </v-col>
        </v-row>
      </v-stepper>
    </template>
  </tattooer-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ClientIncription",
  components: {
    TattooerCard: () => import("@/components/inscription/tattooer/Card"),

    TattooInfo: () => import("@/components/inscription/tattooer/TattooInfo"),

    PiercingInfo: () =>
      import("@/components/inscription/tattooer/PiercingInfo"),
    MicrobladingInfo: () =>
      import("@/components/inscription/tattooer/MicrobladingInfo"),
    LaserInfo: () => import("@/components/inscription/tattooer/LaserInfo"),

    PrepareTimeSelect: () =>
      import("@/components/inscription/studio/PrepareTimeSelect"),
    StudioSelect: () =>
      import("@/components/inscription/customer/StudioSelect"),
    DateTimeSelect: () =>
      import("@/components/inscription/customer/DateTimeSelect"),

    LaborExange: () => import("@/components/inscription/customer/LaborExange"),

    CustomerInfo: () =>
      import("@/components/inscription/customer/CustomerInfo"),
  },
  mounted() {
    this.customer.from = "tattooer";
    if (this.$route.params.artist) {
      this.artist = this.$route.params.artist;
      if (typeof this.artist === "object") this.artist = this.artist.name;
    }

    this.fetchTattooer();
  },
  data() {
    return {
      resevationDone: false,
      e1: 1,
      ap_id: null,
      artist: "tattooer",
    };
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions("tattooers", ["getTattooerByToken"]),
    fetchTattooer() {
      this.getTattooerByToken(this.$route.params.token).then((response) => {
        this.tattooer = response;
        this.$set(this.customer, "tattooer", response);
        this.$store.commit("inscriptions/SET_CUSTOMER", this.customer);
        this.customer.reservation_type = this.$store.getters[
          "inscriptions/getTattooerLaborExange"
        ](response, "labor_exange");
      });
    },
    ...mapActions("inscriptions", ["customerInscription"]),
    save() {
      if (this.$store.state.inscriptions.customerState.inner_part === "Otro")
        this.$store.state.inscriptions.customerState.inner_part =
          this.$store.state.inscriptions.customerState.inner_part_other;
      this.customerInscription().then((data) => {
        //console.log(data);
        this.resevationDone = true;
        this.url(data.id);
      });
    },

    url(id) {
      console.log(this.customer);
      this.ap_id = `${window.location.origin}/?#/cliente/${id}/appointment/${id}`;
    },
  },
};
</script>


<style scoped lang="sass">
.final
  height: 100vh

.v-responsive__sizer
  display: none !important
</style>